html, body, * {
  margin: 0;
  padding: 0;
  border: none;
  overflow-x: hidden;
}

ul, ol {
  list-style-type: none;
}

a, p, h1, h2, h3, h4, h5, h6 {
  text-decoration: none;
  color: black;
  overflow: hidden;
}

.footer-title a:first-child {
  opacity: 0.5;
}
.footer-title a:first-child:hover {
  cursor: default;
  color: unset;
}

.trustworthy-group:last-child {
  margin-bottom: 0;
}

.menuButton {
  backdrop-filter: blur(2px);
}

.lightBg {
  background-image: linear-gradient(45deg, #f6f6f6 25%, transparent 0, transparent 75%, #f6f6f6 0, #f6f6f6), linear-gradient(45deg, #f6f6f6 25%, transparent 0, transparent 75%, #f6f6f6 0, #f6f6f6);
  background-size: 30px 30px;
  background-position: 0 0, 45px 45px;
  background-color: white;
}

.darkBg {
  background-image: linear-gradient(45deg, #222427 25%, transparent 0px, transparent 75%, #222427 0px, #222427), linear-gradient(45deg, #222427 25%, transparent 0px, transparent 75%, #222427 0px, #222427);
  background-size: 30px 30px;
  background-position: 0 0, 45px 45px;
  background-color: black;
}

/*# sourceMappingURL=Styles.css.map */
